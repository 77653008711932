


<template>
  <div id="wangeditor">
    <div :id="'editor' + name" ref="editorElem" style="text-align:left"></div>
  </div>
</template>
<script>
import Wangeditor from 'wangeditor';
import {getIMG, getUQIMGurl} from "@/api/common";
// import upload_editor_api from '@/services/dict/upload_editor_api';
// import { queryOSSKey } from '@/services/dict/oss_api';
export default {
  name: 'editorElem',
  data() {
    return {
      editor: null,
    };
  },
  props: ['content', 'name'], // 接收父组件的方法
  watch: {
    // content(val) {
    //  this.editor.txt.html(this.content);
    // },
    // flag: {
    //  handler(value) {
    //   this.editor.txt.html(this.content);
    //  },
    //  deep: true,
    // },
  },
  created() {
    // this.editor.txt.html(this.content);
  },
  mounted() {
    const that = this;
    // this.editor = new E(this.$refs.editorElem + this.name);
    this.editor = new Wangeditor('#editor' + this.name);
    // this.editor.config.zIndex = 1;
    // return console.log(this.editor);
    this.editor.config.onchange = html => {
      console.log(html);
      this.$emit('getContent',html);
    };
    // that.editor.config.onblur = function(newHtml) {
    //  that.$emit('onblurFun', newHtml);
    // };
    this.editor.config.menus = [
      // 菜单配置
      // 'head', // 标题
      // 'bold', // 粗体
      // 'fontSize', // 字号
      // 'fontName', // 字体
      // 'italic', // 斜体
      // 'underline', // 下划线
      // 'strikeThrough', // 删除线
      // 'foreColor', // 文字颜色
      // 'backColor', // 背景颜色
      // 'link', // 插入链接
      // 'list', // 列表
      // 'justify', // 对齐方式
      // 'quote', // 引用
      // 'emoticon', // 表情
      // 'image', // 插入图片
      // 'table', // 表格
      // 'code', // 插入代码
      // 'undo', // 撤销
      // 'redo', // 重复

      // 'head',
      // 'bold',
      // 'fontSize',
      // 'fontName',
      // 'italic',
      // 'underline',
      // 'strikeThrough',
      // 'indent',
      // 'lineHeight',
      // 'foreColor',
      // 'backColor',
      // 'link',
      // 'list',
      // 'todo',
      // 'justify',
      // 'quote',
      // 'emoticon',
      'image',
      // 'video',
      // 'table',
      // 'code',
      // 'splitLine',
      'undo',
      'redo',
    ];
    // 配置触发 onchange 的时间频率，默认为 200ms
    this.editor.config.onchangeTimeout = 1500; // 修改为 500ms
    // 上传图片到阿里oss
    this.editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
      console.log('object',resultFiles);
      // return
      let Files=resultFiles[0]
        getUQIMGurl({bucket:'product',ext:'.'+Files.type.substr(6)}).then((res)=>{
          console.log('获取URL',res)
          if(res.code==0){
            let UpURL=res.data.upload_url
            let url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(Files,UpURL,Files.type).then((res)=>{
              insertImgFn(url)
            }).catch((err)=>{

            })
          }else {
            this.$message.error('获取上传地址失败！！')
          }
        })
    };
    this.editor.create(); // 创建富文本实例
    if (this.content) {
      this.editor.txt.html(this.content);
    }
  },
  methods: {
    initData() {
      // return new Promise((resolve, reject) => {
      //   queryOSSKey().then(res => {
      //     if (res.data.success) {
      //       resolve(res.data.data);
      //       //  this.ossConfig = res.data.data;
      //     } else {
      //       reject(res);
      //     }
      //   });
      // });
    },
  },

  beforeDestroy() {
    this.editor && this.editor.txt.clear();
  },
};
</script>
<style lang="less" rel="stylesheet/less" scoped>
#wangeditor {
  width: 100%;
}
.w-e-toolbar {
  z-index: 2 !important;
}
.w-e-menu {
  z-index: 2 !important;
}
.w-e-text-container {
  z-index: 1 !important;
}
.tabs-container.affixed {
  z-index: 3 !important;
}
/* table 样式 */
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
pre code {
  display: block;
}

/* ul ol 样式 */
ul,
ol {
  margin: 10px 0 10px 20px;
}
</style>