<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 230px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
        <el-table-column prop="name" label="产品收费项名称" >
        </el-table-column>
        <el-table-column prop="" label="产品收费项" >
          <template #default="scope">
            {{scope.row.charge_name}}
          </template>
        </el-table-column>
<!--        <el-table-column prop="name" label="产品标识" >-->
<!--          <template #default="scope">-->
<!--            ￥ {{scope.row.group_price}}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="name" label="抢购价" >-->
<!--          <template #default="scope">-->
<!--            ￥ {{scope.row.panic_price}}-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="address" label="操作" width="235">
          <template #header>
            <el-button  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary"  @click="skuValueData(scope.row)">收费项规格值</el-button>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
      >
        <el-form-item label="产品收费项名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="产品收费项" prop="charge_id">
          <el-select style="width: 100%" v-model="ruleForm.charge_id" placeholder="">
            <el-option
                v-for="item in chargeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>

        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-drawer
        :title="'('+skuValName+')收费项规格值'"
        v-model="skuValdrawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <div>
        <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords_Sku"></el-input>
        <el-button @click="searchSku()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
      </div>
      <el-table border height="calc(100vh - 230px)" :data="tableDataSku" style="width: 100%;">
        <el-table-column  align="center"
                          type="index"
                          width="55" label="#" >
        </el-table-column>
        <el-table-column prop="area_name" label="城市" >
        </el-table-column>
        <el-table-column prop="is_consult" label="是否面议协商" >
          <template #default="scope">
            <div style="text-align: center">
              <span v-if="scope.row.is_consult==1" style="font-size: 23px;font-weight: 600">√</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price_from" label="价格开始" >
          <template #default="scope">
            ￥ {{scope.row.price_from}}
          </template>
        </el-table-column>
        <el-table-column prop="price_to" label="价格结束" >
          <template #default="scope">
            ￥ {{scope.row.price_to}}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" >
          <template #default="scope">
            ￥ {{scope.row.price}}
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="计价单位" >
        </el-table-column>
        <el-table-column fixed="right" prop="address" label="操作" width="110">
          <template #header>
            <el-button  @click="addSku()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upDataSku(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleDataSku(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChangeSku"
            @current-change="handleCurrentChangeSku"
            :current-page="pageSku"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limitSku"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalSku"
        >
        </el-pagination>
      </div>


      <el-drawer
          :title="'('+skuValName+')收费项规格值新增/修改'"
          v-model="addskuVal"
          size="70%"
          direction="rtl"
          :close-on-click-modal="false"
          destroy-on-close>
        <el-form
            :model="skuValForm"
            :rules="skuValrules"
            ref="skuValForm"
            label-width="120px"
            class="demo-ruleForm">
          <el-form-item label="城市" prop="">
            <el-cascader
                placeholder=""
                clearable
                ref="cascaderHandle"
                style="width: 100%"
                :options="areaArr"
                v-model="skuValForm.area_id"
                :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
                :filterable="true">
            </el-cascader>
          </el-form-item>
          <el-form-item label="是否面议、协商" prop="">
            <div style="display: flex;justify-content: space-between;align-items:center;height: 40px">
              <el-switch
                  style="display: block"
                  v-model="skuValForm.is_consult"
                  active-text="是"
                  inactive-text="否"
                  :active-value="1"
                  :inactive-value="0">
              </el-switch>
            </div>
          </el-form-item>
          <el-form-item label="价格开始" prop="">
            <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="skuValForm.price_from"></el-input-number>
          </el-form-item>
          <el-form-item label="价格结束" prop="">
            <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="skuValForm.price_to"></el-input-number>
          </el-form-item>
          <el-form-item label="价格" prop="price">
            <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="skuValForm.price"></el-input-number>
          </el-form-item>
          <el-form-item label="计价单位" prop="unit">
            <el-input style="width: 100%" v-model="skuValForm.unit"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-if="skuValForm.id" :loading="login" @click="okSkuValAdd('skuValForm',2)">修改</el-button>
            <el-button type="primary" v-else :loading="login" @click="okSkuValAdd('skuValForm',1)">保存</el-button>
          </el-form-item>
        </el-form>
      </el-drawer>
    </el-drawer>

  </div>
</template>
<script>
// @ is an alias to /src
import { getAreaTop , getCharge} from "@/api/common";
import * as axios from "@/api/chargeapi"//本页面接口
export default {
  name: 'Specs',
  props:['nowData'],
  data(){
    return{
      skuValName:'',
      login:false,
      drawer:false,
      skuValdrawer:false,
      addskuVal:false,
      skurowData:[],
      tableData:[],
      areaArr:[],//城市
      chargeArr:[],//收费项
      page:1,
      limit:10,
      total:0,

      tableDataSku:[],
      pageSku:1,
      limitSku:10,
      totalSku:0,
      keywords_Sku:'',

      keywords:'',
      ruleForm:{
        name:'',
        product_id:'',
        charge_id:'',
      },
      skuValForm:{
        area_id:[],
        product_charge_id:'',
        is_consult:0,
        price_from:0,
        price_to:0,
        price:0,
        unit:'',
      },
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        charge_id: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
      },
      skuValrules:{
        area_id: [
          { required: true, message: '请选择城市', trigger: 'blue' },
        ],
        sale_price: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        price: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        unit: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.onArea()
    // this.keyupSubmit()
  },
  methods:{
    // keyupSubmit(){
    //   document.onkeydown=e=>{
    //     let _key=window.event.keyCode;
    //     if(_key===13){
    //       this.search()
    //     }
    //   }
    // },
    onArea(){
      getAreaTop().then((res)=>{
          this.areaArr=res.data
        }).catch((err)=>{

        })
      getCharge().then((res)=>{
          this.chargeArr=res.data.data
        }).catch((err)=>{

        })
    },
    onTable(){
      let form={
        product_id:this.nowData.product_id,
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getproductSkuData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    searchSku(){
      this.pageSku=1
      this.skuValueData(this.skurowData)
    },
    handleSizeChangeSku(val) {
      console.log(`每页 ${val} 条`)
      this.limitSku=val
      this.skuValueData(this.skurowData)
    },
    handleCurrentChangeSku(val) {
      console.log(`当前页: ${val}`)
      this.pageSku=val
      this.skuValueData(this.skurowData)
    },
    skuValueData(row){
      this.skuValName=row.name
      this.skurowData=row
      let form={
        product_charge_id:this.skurowData.id,
        page:this.pageSku,
        limit:this.limitSku,
        keywords:this.keywords_Sku
      }
      axios.getproductSkuValData(form).then((res)=>{
        console.log(res)
        if(res.code==200){
          this.tableDataSku=res.data.data
          this.totalSku=res.data.total
          this.skuValdrawer=true
        }
      }).catch((err)=>{

      })
    },
    addSku(){
      this.skuValForm={
        product_charge_id:this.skurowData.id,
        area_id:[],
        is_consult:0,
        price_from:0,
        price:0,
        price_to:0,
        unit:'',
      }
      this.addskuVal=true
    },
    upDataSku(row){
      console.log(row)
      this.skuValForm.id=row.id
      this.skuValForm.area_id=row.area_id
      this.skuValForm.product_charge_id=Number(row.product_charge_id)
      this.skuValForm.is_consult=Number(row.is_consult)
      this.skuValForm.price_from=Number(row.price_from)
      this.skuValForm.price_to=Number(row.price_to)
      this.skuValForm.price=Number(row.price)
      this.skuValForm.unit=row.unit
      console.log(this.skuValForm)
      this.addskuVal=true
    },
    okSkuValAdd(formName,val){
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddProductSkuVal(this.skuValForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.skuValueData(this.skurowData)
                let that=this
                setTimeout(function () {
                  that.addskuVal=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditProductSkuVal(this.skuValForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.skuValueData(this.skurowData)
                let that=this
                this.login=false
                setTimeout(function () {
                  that.addskuVal=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleDataSku(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelProductSkuVal({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.skuValueData(this.skurowData)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
    add(){
      this.ruleForm={
        name:'',
        charge_id:'',
        product_id:this.nowData.product_id,
      }
      this.drawer=true
    },
    upData(row){
      console.log(row)
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.charge_id=row.charge_id
      this.ruleForm.product_id=this.nowData.product_id
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddProductSku(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditProductSku(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelProductSku({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
  }
}
</script>
<style lang="less" >
.el-checkbox{
  height: 32px;
}
</style>