<template>
 <!-- v-loading="loading"  :element-loading-text="count_num" -->
  <div class="about">
    <div style="display:flex;justify-content: space-between">
      <div>
        <el-input  size="mini" clearable placeholder="关键字" style="width: 200px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
        <el-cascader
            size="mini"
            style="width: 200px;margin-right: 10px"
            placeholder="选择分类"
            clearable
            ref="cascaderHandle"
            :options="category"
            @change="postcategory"
            v-model="serchForm.category_id"
            :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
            :filterable="true">
        </el-cascader>
        <el-select   size="mini" clearable placeholder="选择品牌" style="width: 200px;margin-right: 10px"  v-model="serchForm.brand_id" filterable remote >
          <el-option
              v-for="item in brand"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-select size="mini" clearable style="width: 200px;margin:0 15px" v-model="serchForm.area_id" @clear="clearOption" filterable remote >
                <el-option
                    v-for="item in region"
                    :key="item.id"
                    :label="item.area_name"
                    :value="item.area_id"
                    >
                </el-option>
              </el-select>
        <el-button   size="mini" @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
      </div>
      <el-button   size="mini" @click="daochu()" type="primary" style="margin:10px" icon="el-icon-download">导出产品Excel</el-button>
    </div>

    <el-table  height="calc(100vh - 245px)" :data="tableData" style="width: 100%;">
      <el-table-column prop="id" width="55" label="ID" >
      </el-table-column>
      <el-table-column prop="pic_url"  width="80" label="缩略图" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;">
            <el-image
                v-if="scope.row.logo"
                style="width: 50px; height: 50px"
                :src="scope.row.logo"
                :preview-src-list="[scope.row.logo]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="name" min-width="157" label="产品名称" >
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="area_name" min-width="157" label="地区" >
      </el-table-column>
      <el-table-column prop="category_name" width="137"  label="产品类型" >
        <template #default="scope">
          <el-tag>{{ scope.row.category_name }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="brand_name" label="品牌" >
      </el-table-column>
<!--      <el-table-column prop="sort_desc" min-width="230" label="产品描述" >-->
<!--      </el-table-column>-->
<!--      <el-table-column prop="content" min-width="200" label="内容详情" >-->
<!--      </el-table-column>-->
      <el-table-column prop="sale_status" width="80" label="销售状态" >
        <template #default="scope">
            <span v-if="scope.row.sale_status==1">服务中</span>
            <span v-else>未上架</span>
        </template>
      </el-table-column>
      <el-table-column prop="pay_type" width="80" label="支付模式" >
        <template #default="scope">
          <span v-if="scope.row.pay_type==1">现付</span>
          <span v-else>预付</span>
        </template>
      </el-table-column>
      <el-table-column prop="mode"  width="80" label="模式" >
        <template #default="scope">
          <span v-if="scope.row.mode==1">一口价</span>
          <span v-else>报价</span>
        </template>
      </el-table-column>
      <el-table-column prop="grab_type"  width="80" label="抢单类型" >
        <template #default="scope">
          <span v-if="scope.row.grab_type==1">平台指派</span>
          <span v-else>师傅报价</span>
        </template>
      </el-table-column>
      <el-table-column prop="sale_total"  width="80" label="销售总数" >
        <template #default="scope">
         <el-tag size="mini">{{scope.row.sale_total}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sequence" width="55" label="排序" >
      </el-table-column>
      <el-table-column prop="create_time" width="163" label="添加时间" >
      </el-table-column>
      <el-table-column fixed="right" prop="address" label="操作" width="350">
        <template #header>
          <div style="text-align: right">
            <el-button   size="mini" @click="ctrl_c()" type="primary" style="margin:10px;" icon="el-icon-document-copy">产品复制</el-button>
            <el-button   size="mini" @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </div>
        </template>
        <template #default="scope">
          <el-button-group>
            <el-button   size="mini" @click="ctrl_c(scope.row)" type="primary">复制</el-button>
            <el-button  size="mini" type="primary" v-if="scope.row.is_open_sku==1" @click="addSpacs(scope.row)">规格</el-button>
<!--            <el-button  size="mini" disabled type="primary" v-if="scope.row.is_open_sku==0" @click="addArea(scope.row)">规格</el-button>-->
            <el-button  size="mini" type="primary" v-if="scope.row.is_open_sku==0" @click="addArea(scope.row)">城市</el-button>
            <el-button  size="mini" type="primary" @click="addCharge(scope.row)">收费</el-button>
            <el-button  size="mini" type="primary" @click="addEva(scope.row)">评价</el-button>
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
            <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>

    </div>
    <el-drawer title="产品新增/修改"
        v-model="drawer"
        size="70%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar height="calc(100vh - 160px)" style="padding:0 15px;height:calc(100% - 30px) ">
        <el-form
            :inline="true"
            size="mini"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="90px"
            class="demo-ruleForm">
          <div style="display: flex;flex-wrap: wrap;border: 1px solid #e8e8e8;;padding-top: 20px">
            <el-form-item label="产品名称" prop="name">
              <el-input  style="width:198px" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="产品类型" prop="category_info">
              <el-cascader
                  placeholder=""
                  ref="cascaderHandle"
                  size="mini"
                  :options="category"
                  @change="postcategory"
                  v-model="ruleForm.category_info"
                  :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
                  :filterable="true">
              </el-cascader>
            </el-form-item>
            <el-form-item label="品牌" prop="brand_id">
              <el-select v-model="ruleForm.brand_id" >
                <el-option
                    v-for="item in brand"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="排序" prop="">
              <el-input
                  type="number"
                  clearable
                  style="width:198px"
                  v-model="ruleForm.sequence"
                  :min="1" ></el-input>
            </el-form-item>
            <el-form-item label="销售状态" prop="sale_status">
              <el-select v-model="ruleForm.sale_status" >
                <el-option
                    v-for="item in sale_status"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付模式" prop="pay_type">
              <el-select v-model="ruleForm.pay_type" >
                <el-option
                    v-for="item in pay_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="模式" prop="mode">
              <el-select v-model="ruleForm.mode" >
                <el-option
                    v-for="item in mode"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="抢单类型" prop="grab_type">
              <el-select v-model="ruleForm.grab_type" >
                <el-option
                    v-for="item in grab_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销售总数" prop="">
              <el-input
                  type="number"
                  clearable
                  style="width:198px"
                  v-model="ruleForm.sale_total"
                  :min="0" ></el-input>
            </el-form-item>
          </div>
          <div class="tab">
          <el-form-item  label="LOGO:" prop="logo">
            <el-upload
                style=" width: 100px!important;height: 100px!important"
                class="avatar-uploader"
                :action="UpURL"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :http-request="httpRequest"
                :before-upload="beforeAvatarUpload"
            >
              <img v-if="logo" :src="logo" class="avatar" />
              <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 400 X 高 400</p>
          </el-form-item>
            <el-form-item  label="多图:" prop="">
              <div style="height: 40px">
                <el-upload
                    multiple
                    class="upload-demo"
                    :action="UpURL"
                    :http-request="httparrRequest"
                    :before-upload="beforeAvatarUpload"
                    :file-list="fileList"
                    :show-file-list="false"
                    list-type="picture">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <template #tip>
                    <!--                  <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>-->
                  </template>
                </el-upload>
              </div>
              <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 1080 X 高 972</p>
              <el-scrollbar max-height="240px" style="padding-right: 20px">
                <div class="suo" style="display: flex;flex-wrap: wrap;">
                  <el-card style="margin: 10px;padding: 0!important;height: 100px" v-for="(item,i) in ruleForm.pic_url" shadow="hover">
                    <div class="meng" style="position: relative">
                      <div  class="meng_1" style="display:none;z-index:100;width: 100px;height: 100px;position: absolute;background-color: rgba(127,127,127,0.4);text-align: center;line-height: 100px;">
                        <el-button type="text" style="font-size: 24px;color:white!important;z-index:100" icon="el-icon-delete" @click="delePic(item,i)"></el-button>
                      </div>
                      <el-image
                          v-if="item.url"
                          style="width: 100px; height: 100px"
                          :src="item.url"
                          fit="cover"
                      ></el-image>
                    </div>
                  </el-card>
                </div>
              </el-scrollbar>
            </el-form-item>
          </div>

          <div style="border: 1px solid #e8e8e8;;padding:15px 10px 0 20px">
            <el-form-item label="保证金产品" label-width="100" prop="">
              <div style="margin-right: 30px;display:flex;justify-content: space-between;align-items:center;height: 25px">
                <el-switch
                    style="display: block"
                    v-model="ruleForm.is_deposit"
                    active-text="是"
                    inactive-text="否"
                    :active-value="1"
                    :inactive-value="0">
                </el-switch>
              </div>
            </el-form-item>
            <el-form-item v-if="ruleForm.is_deposit==1" label-width="120"  label="最低保证金金额" prop="deposit_amount">
              <el-input
                  type="number"
                  clearable
                  style="width:198px"
                  v-model="ruleForm.deposit_amount"
                  :min="0" ></el-input>
            </el-form-item>
            <el-form-item label="产品规格" prop="">
              <div style="margin-right: 30px;display:flex;justify-content: space-between;align-items:center;height: 25px">
                <el-switch
                    style="display: block"
                    v-model="ruleForm.is_open_sku"
                    active-text="开启"
                    inactive-text="关闭"
                    :active-value="1"
                    :inactive-value="0">
                </el-switch>
              </div>
            </el-form-item>
            <el-form-item v-if="ruleForm.is_open_sku==0" label-width="120" label="一口价默认金额" prop="price">
              <el-input
                  type="number"
                  style="width:198px"
                  v-model="ruleForm.price"
                  :min="0" ></el-input>
            </el-form-item>
            <el-form-item type="number" :min="0" v-if="ruleForm.is_open_sku==0" label="起拍数" prop="is_exist_count">
              <el-input  style="width:198px" v-model="ruleForm.is_exist_count"></el-input>
            </el-form-item>
            <el-form-item v-if="ruleForm.is_open_sku==0" label="单位" prop="unit">
              <el-input  style="width:198px" v-model="ruleForm.unit"></el-input>
            </el-form-item>
          </div>
          <div style="border: 1px solid #e8e8e8;;padding:15px 10px 0 20px;margin-top: 5px">
            <el-form-item label="上门服务费" label-width="120" prop="basic_fee_id">
              <el-select clearable v-model="ruleForm.basic_fee_id" >
                <el-option
                    v-for="item in basic_fee"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="师傅技能绑定" label-width="120" prop="skill_list">
              <el-cascader :options="options" :props="props" v-model="ruleForm.skill_list" collapse-tags clearable />
            </el-form-item>
            <el-form-item label="地区" label-width="120" prop="area_id">
              <el-select clearable v-model="ruleForm.area_id" >
                <el-option
                    v-for="item in region"
                    :key="item.id"
                    :label="item.area_name"
                    :value="item.area_id">
                </el-option>
              </el-select>
            </el-form-item>
            <div style="width: 100%">
              <el-form-item label-width="190" label="最快上门时间描述" prop="">
                <el-input style="width:30vw" :rows="2" type="textarea" v-model="ruleForm.fastest_tip"></el-input>
              </el-form-item>
            </div>
            
          </div>

          <div class="tab">
            <div style="color: #606266;display: flex;justify-content: space-between"><span> 产品标签</span>  <el-button size="medium" style="z-index: 99999;" type="text" @click="addLabeling()"> 添加标签</el-button> </div>
            <el-scrollbar max-height="100px">
              <el-tag style="margin: 3px" closable  v-for="(item,i) in ruleForm.tags" :key="i" @close="deletTab(item,i)">{{ item.name }}</el-tag>
            </el-scrollbar>
          </div>
          <div class="tab">

            <div style="color: #606266;display: flex;justify-content: space-between"><span> 产品拓展详情</span><el-button size="medium" type="text" @click="addConling()"> 添加拓展详情</el-button></div>
            <el-scrollbar max-height="100px">
              <el-tag type="info" style="margin: 3px"  closable  v-for="(item,i) in ruleForm.content_extend" :key="i" @close="deletCon(item,i)">{{ item.name }}</el-tag>
            </el-scrollbar>
          </div>
          <div class="tab">
            <div style="color: #606266;display: flex;justify-content: space-between"><span> 产品常见问题</span><el-button size="medium" type="text" @click="addQueling()"> 添加常见问题</el-button></div>
            <el-table  max-height="300px" :data="ruleForm.question" style="width: 100%;margin:10px 0 30px 0">
              <el-table-column prop="is_display" label="排序" min-width="110">
                <template #default="scope">
                  <el-input size="mini" type="number" :min="0" v-model="scope.row.sequence"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="问题名称" min-width="120">
                <template #default="scope">
                  <el-tag type="danger" style="margin: 3px" size="mini">{{ scope.row.title }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column fixed="right" prop="unit" label="操作" width="57">
                <template #default="scope">
                  <el-button size="medium" type="text" @click="deletQue(scope.row,scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="tab">
            <div style="color: #606266;display: flex;justify-content: space-between"><span> 产品属性</span> <el-button size="medium" type="text" @click="addAttribute()"> 添加属性</el-button></div>
            <el-table border max-height="300px" :data="ruleForm.attribute" style="width: 100%;margin:10px 0 30px 0">
              <el-table-column prop="name" label="属性名称" width="136"> </el-table-column>
              <el-table-column prop="is_display" label="属性值" min-width="110">
                <template #default="scope">
                  <el-tag type="success" style="margin: 3px" size="mini" v-for="(item,i) in scope.row.attribute_value" :key="i">{{ item.value }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="is_required" label="是否必选" width="78">
                <template #default="scope">
                  <div style="text-align: center;">
                    <span v-if="scope.row.is_required==1">是</span>
                    <span v-else>否</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" prop="unit" label="操作" width="94">
                <template #default="scope">
                  <el-button size="medium" type="text" @click="upaddattr(scope.row)">编辑</el-button>
                  <el-button size="medium" type="text" @click="editattr(scope.row,scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="tab">
<!--          <el-form-item label-position="top" label="产品描述" label-width="70px" prop="">-->
<!--            <el-input type="textarea" :rows="2" v-model="ruleForm.sort_desc"></el-input>-->
            <div style="color: #606266;margin-bottom: 10px">服务内容</div>
            <ueItem :content="ruleForm.sort_desc" :name="'product_sort_desc'" @getContent="getContent"></ueItem>
<!--          </el-form-item>-->
          </div>
          <div class="tab">
            <div style="color: #606266;margin-bottom: 10px">产品详情(只能上传图片！)</div>
            <ueItemImg :content="ruleForm.content" :name="'productImg'" @getContent="getContentImg"></ueItemImg>
          </div>
          <el-drawer
              title="新增属性值"
              :append-to-body="true"
              v-model="innerDrawer" >
            <el-scrollbar height="calc(100vh - 160px)" style="padding-right: 15px;height:calc(100% - 30px) ">
              <el-form
                  size="mini"
                  :model="innerForm"
                  :rules="innerules"
                  ref="innerForm"
                  label-width="110px"
                  class="demo-ruleForm">
                <el-form-item label="属性名称" prop="name">
                  <el-input  v-model="innerForm.name"></el-input>
                </el-form-item>
                <el-form-item label="输入模式单位" prop="">
                  <el-input  v-model="innerForm.unit"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="">
                  <el-input style="width: 100%" type="textarea" :rows="4" v-model="innerForm.tip"></el-input>
                </el-form-item>
                <div style="display: flex;justify-content: space-between;flex-wrap: wrap">
                  <el-form-item label="是否显示属性名" prop="">
                    <div style="display: flex;justify-content: space-between;align-items:center;height: 25px">
                      <el-switch
                          style="display: block"
                          v-model="innerForm.is_display"
                          active-text="是"
                          inactive-text="否"
                          :active-value="1"
                          :inactive-value="0">
                      </el-switch>
                    </div>
                  </el-form-item>
                  <el-form-item label="选择项存在数量" prop="">
                    <div style="display: flex;justify-content: space-between;align-items:center;height: 25px">
                      <el-switch
                          style="display: block"
                          v-model="innerForm.is_exist_count"
                          active-text="是"
                          inactive-text="否"
                          :active-value="1"
                          :inactive-value="0">
                      </el-switch>
                    </div>
                  </el-form-item>
                  <el-form-item label="是否必选" prop="">
                    <div style="display: flex;justify-content: space-between;align-items:center;height: 25px">
                      <el-switch
                          style="display: block"
                          v-model="innerForm.is_required"
                          active-text="是"
                          inactive-text="否"
                          :active-value="1"
                          :inactive-value="0">
                      </el-switch>
                    </div>
                  </el-form-item>
                  <el-form-item label="选择" prop="">
                    <div style="display: flex;justify-content: space-between;align-items:center;height: 25px">
                      <el-switch
                          style="display: block"
                          v-model="innerForm.is_multiple"
                          active-text="多选"
                          inactive-text="单选"
                          :active-value="1"
                          :inactive-value="0">
                      </el-switch>
                    </div>
                  </el-form-item>
                  <el-form-item label="选择模式" prop="">
                    <div style="display: flex;justify-content: space-between;align-items:center;height: 25px">
                      <el-switch
                          style="display: block"
                          v-model="innerForm.mode"
                          active-text="选择"
                          inactive-text="输入"
                          :active-value="1"
                          :inactive-value="0">
                      </el-switch>
                    </div>
                  </el-form-item>
                  <p style="width: 100%;color: rgba(127,127,127,1)"> 属性值</p>
                  <el-scrollbar style="height: 90%" max-height="400px">
                    <el-tag
                        style="margin: 3px"
                        v-for="(tag,i) in innerForm.attribute_value"
                        :key="i"
                        closable
                        type="warning"
                        :disable-transitions="false"
                        @close="handleClose(tag,i)">
                      {{tag.value}}
                    </el-tag>
                    <el-input
                        style="width:88px;margin: 3px"
                        class="input-new-tag"
                        v-if="inputVisible"
                        v-model="inputValue"
                        ref="saveTagInput"
                        size="mini"
                        @keyup.enter.native="handleInputConfirm"
                        @blur="handleInputConfirm"
                    >
                    </el-input>
                    <el-button  style="margin: 3px" v-else class="button-new-tag" size="small" @click="showInput">+ 属性值</el-button>
                  </el-scrollbar>
                </div>
              </el-form>
            </el-scrollbar>
            <div style="text-align: right">
              <el-button  type="primary" v-if="innerType" :loading="login" @click="innerAdd('innerForm',1)">确定</el-button>
              <el-button  type="primary" v-else :loading="login" @click="innerAdd('innerForm',2)">修改</el-button>
            </div>
          </el-drawer>

          <el-drawer
              title="新增标签"
              :append-to-body="true"
              v-model="LabeDrawer" >
            <el-scrollbar style="height: 90%" height="400px">
              <el-checkbox-group v-model="nowtabkList" >
                <el-checkbox v-if="tabkList.length==0" style="margin: 3px"   border disabled label="暂无数据">暂无数据</el-checkbox>
                <el-checkbox v-else style="margin: 3px" v-for="(item ,i) in tabkList" :key="i" border :label="item">{{item.name}}</el-checkbox>
              </el-checkbox-group>
            </el-scrollbar>
            <el-button type="primary" @click="okaddTab()">确定</el-button>
          </el-drawer>

          <el-drawer
              title="新增常见问题"
              :append-to-body="true"
              v-model="LabeDrawerQue" >
            <el-scrollbar style="height: 90%" height="400px">
              <el-checkbox-group v-model="nowQuekList" >
                <el-checkbox v-if="QuekList.length==0" style="margin: 3px"   border disabled label="暂无数据">暂无数据</el-checkbox>
                <el-checkbox v-else style="margin: 3px" v-for="(item ,i) in QuekList" :key="i" border :label="item">{{item.title}}</el-checkbox>
              </el-checkbox-group>
            </el-scrollbar>
            <el-button type="primary" @click="okaddQue()">确定</el-button>
          </el-drawer>
          <el-drawer
              title="新增拓展详情"
              :append-to-body="true"
              v-model="LabeDrawerCon" >
            <el-scrollbar style="height: 90%" height="400px">
              <el-checkbox-group v-model="nowConkList" >
                <el-checkbox v-if="ConkList.length==0" style="margin: 3px"   border disabled label="暂无数据">暂无数据</el-checkbox>
                <el-checkbox v-else style="margin: 3px" v-for="(item ,i) in ConkList" :key="i" border :label="item">{{item.name}}</el-checkbox>
              </el-checkbox-group>
            </el-scrollbar>
            <el-button type="primary" @click="okaddCon()">确定</el-button>
          </el-drawer>
        </el-form>
      </el-scrollbar>
      <div style="text-align: right">
        <el-button  type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
        <el-button  type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
      </div>
    </el-drawer>
    <el-drawer title="产品规格"
               v-model="drawerSku"
               size="80%"
               direction="rtl"
               :close-on-click-modal="false"
               destroy-on-close>
        <Sku :nowData="nowData"></Sku>
<!--        <specs :nowData="nowData"></specs>-->
    </el-drawer>
    <el-drawer title="城市价格"
               v-model="drawerArea"
               size="50%"
               direction="rtl"
               :close-on-click-modal="false"
               destroy-on-close>
        <Area :nowData="nowData"></Area>
    </el-drawer>
    <el-drawer title="产品收费项"
               v-model="drawerCha"
               size="60%"
               direction="rtl"
               :close-on-click-modal="false"
               destroy-on-close>
        <Charge :nowData="nowData"></Charge>
    </el-drawer>
    <el-drawer title="产品评价"
               v-model="drawerEva"
               size="70%"
               direction="rtl"
               :close-on-click-modal="false"
               destroy-on-close>
        <Evaluate :nowData="nowData"></Evaluate>
    </el-drawer>

    <el-drawer title="产品复制" v-model="c_drawereva">
      <div style="display: flex; flex-direction:column; justify-content: left; align-items: left;">
        <el-select size="mini" clearable style="width:80%;margin:0 15px" v-model="copy_area_id" @clear="clearOption" filterable remote >
                <el-option
                    v-for="item in region_copy_list"
                    :key="item.id"
                    :label="item.area_name"
                    :value="item.area_id"
                    >
                </el-option>
              </el-select>
              <el-button size="mini" @click="ok_copy" type="primary" style="margin:20px 0 0 15px;width:80%;" icon="el-icon-document-checked">确认复制</el-button>
      </div>
    </el-drawer>  
  </div>
</template>
<script>
import { ElLoading } from 'element-plus'

import {getUQIMGurl,getIMG,postCateTree,getbrandselect,getBasic} from "@/api/common"//图片上传接口
import * as axios from "@/api/productapi"//本页面接口
import {blob,addDq,AdminaddDq,get} from "@/api/Api"//本页面接口
import Sku from '../components/productChild/sku'
import specs from '../components/productChild/specs'
import Area from '../components/productChild/area'
import Charge from '../components/productChild/charge'
import Evaluate from '../components/productChild/evaluate'
// import { ref } from 'vue';
import ueItem from './ue/ueItem'
import ueItemImg from './ue/ueItemImg'
export default {
  name: 'product',
  components:{
    specs,
    Area,
    Sku,
    Charge,
    Evaluate,
    ueItem,
    ueItemImg,
  },
  data(){
    return {

      props: {
        multiple: true,
        label: 'name',
        value: 'id',
        children: 'children',
      },
      options:[],
      ///*筛选*/
      serchForm:{
        page:'',
        limit:'',
        keywords:'',
        category_id:'',
        brand_id:'',
        area_id:''
      },

      editorConfig:'',//富文本
      /**/
      /**/
      // 添加config属性，可修改菜单
      // myConfig: {
      //   // 编辑器不自动被内容撑高
      //   autoHeightEnabled: false,
      //   // 初始容器高度
      //   initialFrameHeight: 240,
      //   // 初始容器宽度
      //   initialFrameWidth: '100%',
      //   // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
      //   serverUrl: 'http://35.201.165.105:8000/controller.php',
      //   // UEditor 资源文件的存放路径，如果你使用的是 vue-cli2.0 生成的项目，通常不需要设置该选项，默认是：UEDITOR_HOME_URL: '/static/UEditor/'
      //   //但是 vue cli 3.x 生成的项目中，默认值是 process.env.BASE_URL + 'UEditor/'，如果项目不是部署在根目录下，则需要自己配置路径，我的项目是在根目录下，手动下载的UEditor放在了public目录下
      //   UEDITOR_HOME_URL: '/UEditor/',
      //   // 自定义菜单
      //   toolbars: [
      //     ['fullscreen', 'undo', 'redo', 'fontfamily', 'fontsize', 'bold', 'italic', 'underline', 'forecolor', 'fontborder', 'pasteplain', 'background', 'backcolor', 'strikethrough', 'superscript', 'subscript', 'spechars', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', 'horizontal']
      //   ]
      // },
      /**/
      innerType:true,//属性添加/修改按钮状态
      drawerSku:false,//产品规格弹框
      drawerCha:false,//产品收费弹框
      drawerArea:false,//产品城市价格弹框
      drawerEva:false,//产品评价
      /*标签--*/
      tabkListarr:[],//标签组
      tabkList:[],//标签
      nowtabkList:[],//选中标签
      LabeDrawer:false,//标签
      /*标签--*/
      /*常见问题--*/
      QuekListarr:[],//问题组
      QuekList:[],//问题
      nowQuekList:[],//选中问题
      LabeDrawerQue:false,//标签
      /*常见问题--*/
      /*常见问题--*/
      ConkListarr:[],//详情组
      ConkList:[],//详情
      nowConkList:[],//选中详情
      LabeDrawerCon:false,//标签
      /*常见问题--*/

      rowData:{},
      nowData:{
        product_id:'',
      },
      UpURL:'',
      login:false,
      drawer:false,//新增修改
      innerDrawer:false,//属性
      category:[],//产品类型
      brand:[],//品牌
      fileList:[],//缩略图
      basic_fee:[],//收费项
      /*根列表*/
      tableData:[],
      page:1,
      limit:10,
      total:0,
      tabPage:1,
      tabLimit:400,
      tabTotal:0,
      /*////////////////*/
      logo:'',
      keywords:'',
      innerForm:{
        is_display:0,// 是否显示属性名称
        is_exist_count:0, //选择项存在数量。0：否，1：是
        is_multiple:0 , //选择模式时，0：单选，1：多选
        is_required:0, // 是否必选。0：否，1：是
        mode:0, // 选择模式。0：输入，1：选择
        name :'', //属性名称
        product_id:'', //
        unit:'', // 输入模式时单位
        user:'', //_id
        tip:'', //_id
        attribute_value:[],
      },
      ruleForm:{
        sequence:50,
        category_info:'',
        sale_status:'',
        pay_type:'',
        mode:'',
        grab_type:'',
        logo:'',
        pic_url:[],
        brand_id:'',
        tags:[],
        question:[],
        content_extend:[],
        id:'',
        sort_desc:'',
        content:'',
        name:'',
        attribute:[],
        is_deposit:0,
        skill_list:[],
        deposit_amount:0,
        sale_total:0,
        is_exist_count:0,
        basic_fee_id:'',
        is_open_sku:1,
        price:0,
        unit:'',
        fastest_tip:'',
        area_id:''
      },
      rules:{
        logo: [
          { required: true, message: '请上传图片', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
        category_info: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        brand_id: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        sale_status: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        pay_type: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        mode: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        grab_type: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        is_deposit: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        deposit_amount: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        is_exist_count: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        is_open_sku: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        skill_list: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        price: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        unit: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        area_id: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
      },
      innerules:{
          name: [
        { required: true, message: '请填写名称', trigger: 'change' },
      ],
    },
    /*属性值*/
      inputVisible: false,
      inputValue: '',
    /*属性值*/
      /**/
      sale_status:[
        {
          id:0,
          name:'未上架'
        },
        {
          id:1,
          name:'服务中'
        }
      ],
      pay_type:[
        {
          id:0,
          name:'预付'
        },
        {
          id:1,
          name:'现付'
        }
      ],
      mode:[
        {
          id:0,
          name:'报价'
        },
        {
          id:1,
          name:'一口价'
        }
      ],
      grab_type:[
        {
          id:0,
          name:'师傅报价'
        },
        {
          id:1,
          name:'平台指派'
        }
      ],
      // 地区
      region:[],
      c_drawereva:false,
      region_copy_list:[],
      copy_area_id:'',
      loading:false,
      text_:''
      /**/
    }
  },
  created() {
    // this.editorConfig = {
    //   autoHeightEnabled: false,
    //   initialFrameHeight: 600,
    //   // 初始容器宽度
    //   zIndex:9999,
    //   initialFrameWidth: '100%',
    //   UEDITOR_HOME_URL: '/UEditor/', // 访问 UEditor 静态资源的根路径，可参考常见问题1
    //   // serverUrl: '//ueditor.szcloudplus.com/cos', // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    // };
    this.onselect()
    this.onTable()
    this.onTablist()
    this.onQuelist()
    this.onConlist()
    this.keyupSubmit()
    this.onOptions()
    this.getaddDq()
  },
  mounted(){
  },
  methods:{
    daochu(){
        let url = process.env.VUE_APP_BASE_URL+'/dm/exportProduct'
        console.log(url)
        //创建一个a标签
        let link = document.createElement('a');
        link.style.display = 'none'
        link.href = url
        // link.setAttribute('download', name+ '.xls')//自定义下载的文件的名称
        document.body.appendChild(link);//添加a标签
        link.click()
        document.body.removeChild(link);//移除a标签
    },
    getContent(html){
      this.ruleForm.sort_desc=html
      // console.log('父组件接受',html)
    },
    getContentImg(html){
      // console.log('父组件接受img',html)
      this.ruleForm.content=html
    },
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    /*技能*/
    onOptions(){
      let data={
      }
      axios.getserviceskill(data).then((res)=>{
        // console.log('getproduTag',res)
        this.options=res.data
      }).catch((err)=>{

      })
    },
    /*技能*/
    /*规格*/
    addSpacs(row){
      // console.log(row)
      this.nowData.product_id=row.id
      this.drawerSku=true
    },
    /*规格*/
    /*城市*/
    addArea(row){
      // console.log(row)
      this.nowData.product_id=row.id
      this.drawerArea=true
    },
    /*城市*/
    /*收费项*/
    addCharge(row){
      // console.log(row)
      this.nowData.product_id=row.id
      this.drawerCha=true
    },
    /*收费项*/
    /*评价*/
    addEva(row){
      // console.log(row)
      this.nowData.product_id=row.id
      this.drawerEva=true
    },
    /*评价*/
    onselect(){
      /*分类*/
      postCateTree().then((res)=>{
        this.category=res.data
      }).catch((err)=>{
      })
      /*分类*/
      /*品牌*/
      getbrandselect().then((res)=>{
        this.brand=res.data.data
      }).catch((err)=>{
      })
      /*品牌*/
      getBasic().then((res)=>{
        this.basic_fee=res.data
      }).catch((err)=>{
      })
    },
    onTablist(){
      let data={
        page:this.tabPage,
        limit:this.tabLimit,
      }
      axios.getproduTag(data).then((res)=>{
        // console.log('getproduTag',res)
        this.tabkListarr=res.data.data
      }).catch((err)=>{

      })
    },
    onQuelist(){
      let data={
        page:this.tabPage,
        limit:this.tabLimit,
      }
      axios.getproduQue(data).then((res)=>{
        // console.log('getproduQue',res)
        this.QuekListarr=res.data.data
      }).catch((err)=>{

      })
    },
    onConlist(){
      let data={
        page:this.tabPage,
        limit:this.tabLimit,
      }
      axios.getproduCon(data).then((res)=>{
        // console.log('getproduCon',res)
        this.ConkListarr=res.data.data
      }).catch((err)=>{

      })
    },
    onTable(){
      this.serchForm.page=this.page
      this.serchForm.limit=this.limit
      this.serchForm.keywords=this.keywords
      axios.getproductData(this.serchForm).then((res)=>{
        // console.log(res)
        this.tableData=res.data.data
        // console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    postcategory(){
      this.$refs.cascaderHandle.dropDownVisible = false
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        sequence:50,
        category_info:'',
        sale_status:'',
        pay_type:'',
        mode:'',
        grab_type:'',
        logo:'',
        pic_url:[],
        brand_id:'',
        tags:[],
        question:[],
        skill_list:[],
        content_extend:[],
        id:'',
        sort_desc:'',
        content:'',
        name:'',
        attribute:[],
        is_deposit:0,
        deposit_amount:0,
        sale_total:0,
        is_exist_count:0,
        basic_fee_id:'',
        is_open_sku:1,
        price:0,
        unit:'',
        fastest_tip:'',
      }
      this.logo=''
      this.drawer=true
    },
    upData(row){
      // console.log('当前商品数据',row)
      axios.getProductData({id:row.id}).then((res)=>{
        if(res.code==200){
          this.rowData=res.data
          this.ruleForm.sequence=res.data.sequence
          this.ruleForm.category_info=res.data.category_info
          this.ruleForm.sale_status=res.data.sale_status
          this.ruleForm.pay_type=res.data.pay_type
          this.ruleForm.mode=res.data.mode
          this.ruleForm.grab_type=res.data.grab_type
          this.ruleForm.logo=res.data.logo
          this.ruleForm.brand_id=res.data.brand_id
          this.ruleForm.tags=res.data.tags
          this.ruleForm.question=res.data.question
          this.ruleForm.content_extend=res.data.content_extend
          this.ruleForm.id=res.data.id
          this.ruleForm.sort_desc=res.data.sort_desc
          this.ruleForm.content=res.data.content
          this.ruleForm.name=res.data.name
          this.ruleForm.attribute=res.data.attribute
          this.ruleForm.is_deposit=res.data.is_deposit
          this.ruleForm.deposit_amount=res.data.deposit_amount
          this.ruleForm.is_exist_count=Number(res.data.is_exist_count)
          this.ruleForm.sale_total=Number(res.data.sale_total)
          this.ruleForm.basic_fee_id=res.data.basic_fee_id
          this.ruleForm.is_open_sku=res.data.is_open_sku
          this.ruleForm.price=res.data.price
          this.ruleForm.unit=res.data.unit
          this.ruleForm.fastest_tip=res.data.fastest_tip
          this.ruleForm.skill_list=res.data.skill_list
           this.ruleForm.area_id = res.data.area_id
          this.logo=res.data.logo
          if(res.data.images.length<1){
            this.ruleForm.pic_url=[]
          }else {
            this.ruleForm.pic_url=res.data.images
          }
          this.drawer=true
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('服务器问题！请联系管理员！！')
      })
    },
    /*标签---------------*/
    addLabeling(){
      this.tabkList=[]
      var array = this.tabkListarr
       　　var array2 = this.ruleForm.tags;
       for(let key in array) {  //用来遍历的数组使用两个数组长度比较长的，不然可能没有效果
         　　var stra = array[key];
         　　var count = 0;
        　　for(var j = 0; j < array2.length; j++) {
           　　　　var strb = array2[j];
           　　　　if(stra.id == strb.tag_id) {
            　　　　count++;
             　　　　　　}
           　　　　}
       　　if(count === 0) {
              this.tabkList.push(stra);
           　　}
         　}
      // // console.log('不重复的值为：',this.tabkList);
      this.nowtabkList=[]

      this.LabeDrawer=true

    },
    okaddTab(){
      this.nowtabkList.forEach((item)=>{
        let arr={
          tag_id:item.id,
          name:item.name,
        }
        this.ruleForm.tags.push(arr)
      })
      // // console.log('添加的标签', this.ruleForm.tags)
      this.LabeDrawer=false
    },
    //产品标签删除
    deletTab(row,val){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.postdelTags({id:row.id}).then((res)=>{
            if(res.code==200){
              this.$message.success('删除成功')
              this.ruleForm.tags.splice(val,1)
            }else {
              this.$message.error(res.msg)
            }
          }).catch((err)=>{
            this.$message.error('服务器报错！！请联系管理员')
          })
        })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.ruleForm.tags.splice(val,1)
      }
    },//删除标签
    /*标签--------------*/

    /*问题---------------*/
    addQueling(){
      this.QuekList=[]
      var array = this.QuekListarr
       　　var array2 = this.ruleForm.question;
       for(let key in array) {  //用来遍历的数组使用两个数组长度比较长的，不然可能没有效果
         　　var stra = array[key];
         　　var count = 0;
        　　for(var j = 0; j < array2.length; j++) {
           　　　　var strb = array2[j];
           　　　　if(stra.id == strb.question_id) {
            　　　　count++;
             　　　　　　}
           　　　　}
       　　if(count === 0) {
              this.QuekList.push(stra);
           　　}
         　}
      // // console.log('不重复的值为：',this.tabkList);
      this.nowQuekList=[]
      this.LabeDrawerQue=true
    },
    okaddQue(){
      this.nowQuekList.forEach((item)=>{
        let arr={
          question_id:item.id,
          title:item.title,
          sequence:this.ruleForm.question.length+1,
        }
        this.ruleForm.question.push(arr)
      })
      // // console.log('添加的标签', this.ruleForm.tags)
      this.LabeDrawerQue=false
    },
    deletQue(row,val){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.postdelQuestion({id:row.id}).then((res)=>{
            if(res.code==200){
              this.$message.success('删除成功')
              this.ruleForm.question.splice(val,1)
            }else {
              this.$message.error(res.msg)
            }
          }).catch((err)=>{
            this.$message.error('服务器报错！！请联系管理员')
          })
        })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.ruleForm.question.splice(val,1)
      }
    },//删除标签
    /*标签--------------*/

    /*详情---------------*/
    addConling(){
      this.ConkList=[]
      var array = this.ConkListarr
       　　var array2 = this.ruleForm.content_extend;
       for(let key in array) {  //用来遍历的数组使用两个数组长度比较长的，不然可能没有效果
         　　var stra = array[key];
         　　var count = 0;
        　　for(var j = 0; j < array2.length; j++) {
           　　　　var strb = array2[j];
           　　　　if(stra.id == strb.content_id) {
            　　　　count++;
             　　　　　　}
           　　　　}
       　　if(count === 0) {
              this.ConkList.push(stra);
           　　}
         　}
      // // console.log('不重复的值为：',this.tabkList);
      this.nowConkList=[]
      this.LabeDrawerCon=true
    },
    okaddCon(){
      this.nowConkList.forEach((item)=>{
        let arr={
          content_id:item.id,
          name:item.name,
        }
        this.ruleForm.content_extend.push(arr)
      })
      // // console.log('添加的标签', this.ruleForm.tags)
      this.LabeDrawerCon=false
    },
    // 产品拓展详情删除
    deletCon(row,val){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.postdelContent({id:row.id}).then((res)=>{
            if(res.code==200){
              this.$message.success('删除成功')
              this.ruleForm.content_extend.splice(val,1)
            }else {
              this.$message.error(res.msg)
            }
          }).catch((err)=>{
            this.$message.error('服务器报错！！请联系管理员')
          })
        })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.ruleForm.content_extend.splice(val,1)
      }
    },//删除标签
    /*标签--------------*/
    /*属性--------------*/
    addAttribute(){
      this.innerType=true
      this.innerForm={
        is_display:0,// 是否显示属性名称
        is_exist_count:0, //选择项存在数量。0：否，1：是
        is_multiple:0 , //选择模式时，0：单选，1：多选
        is_required:0, // 是否必选。0：否，1：是
        mode:0, // 选择模式。0：输入，1：选择
        name :'', //属性名称
        product_id:this.rowData.product_id, //
        unit:'', // 输入模式时单位
        user:'', //_id
        tip:'', //_
        attribute_value:[],
      }
      this.innerDrawer=true
    },
    upaddattr(row){
      this.innerType=false
      this.innerForm=row
      this.innerDrawer=true
    },
    innerAdd(formName,val){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr={
            is_display:this.innerForm.is_display ,// 是否显示属性名称
            is_exist_count:this.innerForm.is_exist_count, //选择项存在数量。0：否，1：是
            is_multiple:this.innerForm.is_multiple , //选择模式时，0：单选，1：多选
            is_required:this.innerForm.is_required, // 是否必选。0：否，1：是
            mode:this.innerForm.mode, // 选择模式。0：输入，1：选择
            name :this.innerForm.name, //属性名称
            product_id:this.rowData.id, //
            unit:this.innerForm.unit, // 输入模式时单位
            user:this.innerForm.user, //_id
            tip:this.innerForm.tip, //_id
            attribute_value:this.innerForm.attribute_value,

          }
          if(this.innerForm.id){
            arr.id=this.innerForm.id
          }
          if(val==1){
            this.ruleForm.attribute.push(arr)

          }else {
            this.$message.success('修改成功')
            // this.ruleForm.attribute[val].is_display=this.innerForm.is_display // 是否显示属性名称
            // this.ruleForm.attribute[val].is_exist_count=this.innerForm.is_exist_count //选择项存在数量。0：否，1：是
            // this.ruleForm.attribute[val].is_multiple=this.innerForm.is_multiple  //选择模式时，0：单选，1：多选
            // this.ruleForm.attribute[val].is_required=this.innerForm.is_required // 是否必选。0：否，1：是
            // this.ruleForm.attribute[val].mode=this.innerForm.mode// 选择模式。0：输入，1：选择
            // this.ruleForm.attribute[val].name=this.innerForm.name //属性名称
            // this.ruleForm.attribute[val].product_id=this.rowData.id //
            // this.ruleForm.attribute[val].unit=this.innerForm.unit // 输入模式时单位
            // this.ruleForm.attribute[val].user=this.innerForm.user //_id
            // this.ruleForm.attribute[val].attribute_value=this.innerForm.attribute_value
            // this.ruleForm.attribute[val].id=this.innerForm.id
          }
          this.innerDrawer=false
        } else {
          // console.log('error submit!!')
          return false
        }
      })

    },
    //属性删除
    editattr(row,i){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
              axios.postdelAttr({id:row.id}).then((res)=>{
                if(res.code==200){
                  this.$message.success('删除成功')
                  this.ruleForm.attribute.splice(i,1)
                }else {
                  this.$message.error(res.msg)
                }
              }).catch((err)=>{
                this.$message.error('服务器报错！！请联系管理员')
              })
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        // console.log(i)
        this.ruleForm.attribute.splice(i,1)
      }
    },
    /*属性值*/
    handleClose(tag,i) {
      // console.log(tag)
      if(tag.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.postdelAttrValue({id:tag.id}).then((res)=>{
            if(res.code==200){
              this.$message.success('删除成功')
              this.innerForm.attribute_value.splice(i,1)
            }else {
              this.$message.error(res.msg)
            }
          }).catch((err)=>{
            this.$message.error('服务器报错！！请联系管理员')
          })
        }).catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.innerForm.attribute_value.splice(i, 1);
      }
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = {value:this.inputValue};
      if (inputValue.value) {
        this.innerForm.attribute_value.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    /*属性值*/
    /*属性--------------*/
    /*列表新增修改*/
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
          let arr=[]
          this.ruleForm.skill_list.forEach((item)=>{
            if(typeof(item)=='object'){
              arr.push(item[item.length-1])
            }else {
              arr.push(item)
            }
          })
          console.log('afff',arr)
          this.ruleForm.skill_list=arr
        if (valid) {
          if(val==1){
            axios.postaddProduct(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditProduct(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }

        } else {
          this.login=false
          this.$message.error('请填写必填项！！')
          return false
        }
      })
    },
    delePic(val,i){
      console.log('img',val)
      if(val.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.postdelePic({id:val.id}).then((res)=>{
              // this.$message.success(res.msg)
              this.ruleForm.pic_url.splice(i,1)
          }).catch((err)=>{

          })
        }).catch(() => {
          this.$message({
            type: 'error',
            message: '已取消删除',
          })
        })
      }else {
        this.ruleForm.pic_url.splice(i,1)
      }

      // console.log(val,i)
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelProduct({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
    handleAvatarSuccess(res, file) {
      this.logo = URL.createObjectURL(file.raw)
    },
    //
    //LOGO上传图片方法
    httpRequest(options) {
      this.login=true
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            this.ruleForm.logo=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })

          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    //
    //缩略多图上传图片方法
    httparrRequest(options) {
      this.login=true
      let url=''
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.pic_url.push({url:url})
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })

          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    // 获取城市地区列表数据
    async getaddDq(){
      const {data:res} = await addDq()
      console.log(res);
      this.region = res
      this.region_copy_list = res
      this.serchForm.area_id = this.region[0].area_id
      this.onTable()
    },
    clearOption(){
      this.serchForm.area_id = this.region[0].area_id
      this.onTable()
    },
    ctrl_c(row){
      if(row){
        this.c_drawereva = true
        this.product_id = row.id
      }else{
        this.logging_copy()
        this.c_drawereva = true
      }
      
    },
    async ok_copy(){
      if(this.product_id){
        const res = await get({product_id:this.product_id,area_id:this.copy_area_id},"/api/admin/productOneCopy")
        if(res.code === 200){
          this.$message.success(res.msg)
          this.c_drawereva = false
        }
      }else{
        const res = await get({area_id:this.copy_area_id},"/api/admin/productCopy")
        if(res.data){
         this.logging_copy()
        }
      }
      },
      async logging_copy(){
        let this_ = this
        const res_ = await get({},"/api/admin/noticeCrontab")
             if(res_.data.total_count>0){
                ElLoading.service({text:`复制中...还剩${res_.data.total_count}个未复制`,background:'#000000ba'})
                this.c_drawereva = false
                setTimeout( _ => {
                  this_.logging_copy()
                },1000)
             }else{
               const loadingInstance =  ElLoading.service({text:`复制中...还剩${res_.data.total_count}个未复制`,background:'#000000ba'})
                this.$nextTick(() => {
                  loadingInstance.close()
                })
             }
    },
    },
    
}
</script>
<style lang="less" >
.tab{
  margin: 5px 0 ;
  padding: 10px 20px;
  border: 1px solid #e8e8e8;
}
.avatar-uploader .el-upload {
  width: 98px!important;
  height: 98px!important;
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{
  width: 98px!important;
  height: 98px!important;
}
.el-checkbox{
  height: 32px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 98px!important;
  height: 98px!important;
  line-height: 98px;
  text-align: center;
}
.avatar {
  width: 98px!important;
  height: 98px!important;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
.suo .el-card__body{
  padding: 0!important;
}
.meng:hover .meng_1{
  display: block!important;
}
.el-loading-spinner .el-loading-text {
    color: var(--el-color-primary);
    margin: 3px 0;
    font-size: 25px;
}
</style>