//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*产品接口---------------------------*/
//获取产品列表接口
export function getproductData(obj,url) {
    return request({
        url: '/api/admin/product',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//新增产品接口
export function postaddProduct(obj,url) {
    return request({
        url: '/api/admin/product/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品接口
export function posteditProduct(obj,url) {
    return request({
        url: '/api/admin/product/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品接口
export function postdelProduct(obj,url) {
    return request({
        url: '/api/admin/product/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除多图接口
export function postdelePic(obj) {
    return request({
        url: '/api/admin/product/delImage',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}//删除产品问题接口
export function postdelQuestion(obj) {
    return request({
        url: '/api/admin/product/delQuestion',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}//删除产品拓展详情接口
export function postdelContent(obj) {
    return request({
        url: '/api/admin/product/delContent',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}//删除产品标签
export function postdelTags(obj) {
    return request({
        url: '/api/admin/product/delTags',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

//删除产品属性
export function postdelAttr(obj) {
    return request({
        url: '/api/admin/product/delAttr',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}//删除产品属性值
export function postdelAttrValue(obj) {
    return request({
        url: '/api/admin/product/delAttrValue',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}//产品标签接口
export function getproduTag(obj) {
    return request({
        url: '/api/admin/product/tag',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}//师傅技能
export function getserviceskill(obj) {
    return request({
        url: '/api/admin/serviceskill',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}//产品问题接口
export function getproduQue(obj) {
    return request({
        url: '/api/admin/product/question',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}//产品详情接口
export function getproduCon(obj) {
    return request({
        url: '/api/admin/product/content',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}//单个产品数据接口
export function getProductData(obj) {
    return request({
        url: '/api/admin/product/getInfo?is_attr=1',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
