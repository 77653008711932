<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
      <el-table-column width="126" prop="member_name" label="评价人" >
      </el-table-column>
      <el-table-column width="100" prop="member_profile_url" label="评价人头像" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;">
            <el-image
                v-if="scope.row.member_profile_url"
                style="width: 50px; height: 50px"
                :src="scope.row.member_profile_url"
                :preview-src-list="[scope.row.member_profile_url]">
            </el-image>
          </div>
        </template>
      </el-table-column>
        <el-table-column prop="name" width="152" label="评分" >
          <template #default="scope">
            <div style="display: flex;justify-content: space-between;align-items:center;height: 40px">
              <el-rate
                  v-model="scope.row.score"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}">
              </el-rate>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" min-width="130" show-overflow-tooltip label="评价内容" >
        </el-table-column>
      <el-table-column min-width="80" prop="member_profile_url" label="评价照片" >
        <template #default="scope">
          <el-space wrap>
<!--            <el-image-->
<!--                v-for="(item,i) in scope.row.images"-->
<!--                :key="i"-->
            <el-image
                style="width: 50px; height: 50px;margin:5px "
                :src="scope.row.images[0].url"
                :preview-src-list="scope.row.images_url">
            </el-image>
          </el-space>
        </template>
      </el-table-column>
        <el-table-column prop="review_time" width="180" label="评价时间" >
        </el-table-column>
        <el-table-column fixed="right" prop="address" label="操作" width="70">
          <template #header>
            <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
<!--                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>-->
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar height="calc(100vh - 160px)" style="padding-right: 15px;height:calc(100% - 30px) ">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm">
        <el-form-item label="评价人" prop="member_id">
          <el-select
              style="width: 100%"
              v-model="ruleForm.member_id"
              filterable
              remote
              clearable
              reserve-keyword
              placeholder="输入姓名、手机号查询"
              :remote-method="onhigher"
              :loading="loading">
            <el-option
                v-for="item in higherArr"
                :key="item.id"
                :label="item.nickname+'-'+item.mobile_phone"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评分" prop="score">
          <div style="display: flex;justify-content: space-between;align-items:center;height: 40px">
            <el-rate
                v-model="ruleForm.score"
                show-score
                text-color="#ff9900"
                score-template="{value}">
            </el-rate>
          </div>
        </el-form-item>
        <el-form-item label="评价内容" prop="content">
          <el-input type="textarea" :rows="4"  v-model="ruleForm.content"></el-input>
        </el-form-item>
        <el-form-item  label="多图" prop="">
          <div style="height: 40px">
            <el-upload
                multiple
                class="upload-demo"
                :action="UpURL"
                :http-request="httparrRequest"
                :before-upload="beforeAvatarUpload"
                :file-list="fileList"
                :show-file-list="false"
                list-type="picture">
              <el-button size="small" type="primary">点击上传</el-button>
<!--              <template #tip>-->
                <!--                  <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>-->
<!--              </template>-->
            </el-upload>
          </div>
<!--          <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 1080 X 高 972</p>-->
          <el-scrollbar max-height="250px" style="padding-right: 20px">
            <div class="suo" style="display: flex;flex-wrap: wrap;">
              <el-card style="margin: 10px;padding: 0!important;height: 100px" v-for="(item,i) in ruleForm.pic_url" shadow="hover">
                <div class="meng" style="position: relative">
                  <div  class="meng_1" style="display:none;z-index:100;width: 100px;height: 100px;position: absolute;background-color: rgba(127,127,127,0.4);text-align: center;line-height: 100px;">
                    <el-button type="text" style="font-size: 24px;color:white!important;z-index:100" icon="el-icon-delete" @click="delePic(item,i)"></el-button>
                  </div>
                  <el-image
                      v-if="item.url"
                      style="width: 100px; height: 100px"
                      :src="item.url"
                      fit="cover">
                  </el-image>
                </div>
              </el-card>
            </div>
          </el-scrollbar>
        </el-form-item>
      </el-form>
      </el-scrollbar>
      <div style="text-align: right">
        <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
        <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/Api"
import {getIMG, getUQIMGurl} from "@/api/common";
//本页面接口
export default {
  name: 'brand',
  props:['nowData'],
  data(){
    return{
      UpURL:'',
      loading:false,
      login:false,
      drawer:false,
      higherArr:[],//评价人
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      fileList:[],//缩略图
      ruleForm:{
        product_id:'',
        score:'',
        content:'',
        pic_url:[],
      },
      rules:{
        member_id: [
          { required: true, message: '请选择评价人', trigger: 'change' },
        ],
        score: [
          { required: true, message: '请选择评分', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请填写评价内容', trigger: 'change'},
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.onhigher()
    this.keyupSubmit()
  },
  methods:{
    /*远程搜索*/
    onhigher(query){
      let form={
        page:1,
        limit:200,
        keywords:query
      }
      this.loading = true;
      axios.get(form,'/api/admin/member').then((res)=>{
        console.log(res)
        this.higherArr=res.data.data
        console.log('this.tableData',this.tableData)
        this.loading = false;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    /*远程搜索*/
  /*多图*/
    //缩略多图上传图片方法
    httparrRequest(options) {
      this.login=true
      let url=''
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.pic_url.push({url:url})
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })

          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    delePic(val,i){
      console.log('img',val)
      if(val.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.post({id:val.id},'/api/admin/evaluate/delReviewImage').then((res)=>{
            // this.$message.success(res.msg)
            this.ruleForm.pic_url.splice(i,1)
          }).catch((err)=>{

          })
        }).catch(() => {
          this.$message({
            type: 'error',
            message: '已取消删除',
          })
        })
      }else {
        this.ruleForm.pic_url.splice(i,1)
      }

      // console.log(val,i)
    },

    /*多图*/
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        product_id:this.nowData.product_id
      }
        axios.get(form,'/api/admin/evaluate').then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        product_id:this.nowData.product_id,
        score:5,
        content:'',
        pic_url:[],
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.product_id=this.nowData.product_id
      this.ruleForm.score=row.score
      this.ruleForm.content=row.content
      this.ruleForm.pic_url=row.images
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.post(this.ruleForm,'/api/admin/evaluate/add').then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.post(this.ruleForm,'/api/admin/evaluate/edit').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }

        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.post({id:row.id},'/api/admin/evaluate/del').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
.suo .el-card__body{
  padding: 0!important;
}
.meng:hover .meng_1{
  display: block!important;
}
</style>