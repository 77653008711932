//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*产品规格接口---------------------------*/
//获取产品规格列表接口
export function getproductSkuData(obj) {
    return request({
        url: '/api/admin/product-sku',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品规格接口
export function postaddProductSku(obj,url) {
    return request({
        url: '/api/admin/product-sku/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品规格接口
export function posteditProductSku(obj,url) {
    return request({
        url: '/api/admin/product-sku/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品规格接口
export function postdelProductSku(obj,url) {
    return request({
        url: '/api/admin/product-sku/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

/*产品问题接口---------------------------*/
//获取产品问题列表接口
export function getproductSkuValData(obj) {
    return request({
        url: '/api/admin/product-sku/value',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品问题接口
export function postaddProductSkuVal(obj,url) {
    return request({
        url: '/api/admin/product-sku/value/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品问题接口
export function posteditProductSkuVal(obj,url) {
    return request({
        url: '/api/admin/product-sku/value/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品问题接口
export function postdelProductSkuVal(obj,url) {
    return request({
        url: '/api/admin/product-sku/value/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

