<template>
  <div class="about">
    <div style="padding:20px;">
      <el-steps  :active="showNum" finish-status="success" simple style="margin-bottom: 20px">
        <el-step title="步骤 1"></el-step>
        <el-step title="步骤 2"></el-step>
        <el-step title="步骤 3"></el-step>
      </el-steps>
      <div v-show="showNum==1">
        <div class="goods-spec">
          <span>规格</span>
          <el-button :loading="loading" size="mini" type="primary" @click="addPrivateSpec" class="goods-spec-add">添加规格</el-button>
        </div>
        <el-scrollbar max-height="calc(100vh - 270px)">
          <div class="goods-container" v-for="(attr, index) in attr_group_list" :key="index">
            <div class="goods-content">
              <div class="goods-content-box">
                <div class="goods-content-left">
                  <el-form label-width="80px" style="width:90%">
                    <div style="display: flex">
                      <div style="width:calc(100% - 270px)">
                        <el-form-item label="规格名">
                          <el-input  size="mini" v-model="attr.attr_group_name" placeholder="请输入规格名"></el-input>
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item label="排序">
                          <el-input-number :min="0"  controls-position="right" size="mini" v-model="attr.sequence" placeholder=""></el-input-number>
                        </el-form-item>
                      </div>
                    </div>

                    <el-form-item label="规格值">
                      <el-tag  v-for="tag in attr.attr_list" :key="tag" closable :disable-transitions="false" @click="upTag(attr,tag,index)" @close="handleClose(tag, attr)">
                        <span style="cursor:pointer">{{ tag.attr_name }}</span>
                      </el-tag>
                      <el-button class="button-new-tag" size="mini" @click="showInput(attr,1)">+ 添加</el-button>
                      <el-drawer
                          direction="rtl"
                          :append-to-body="true"
                          destroy-on-close
                          @closed="offFrawer()"
                          :close-on-click-modal="false"
                          v-model="attr.inputVisible"
                          title="只显示第一个类型下的规格服务内容"
                          size="40%">
                        <el-scrollbar height="calc(100vh - 160px)" style="padding:0 15px;height:calc(100% - 30px) ">
                        <div>
                          <el-form label-width="40px">
                            <div style="display: flex">
                              <div>
                                <el-form-item label="名称">
                                  <el-input
                                      style="margin-bottom: 5px"
                                      class="input-new-tag"
                                      v-model="ruleForm.attr_name"
                                      :ref="`saveTagInput${index}`"
                                      size="mini">
                                    <!--                              @keyup.enter.native="handleInputConfirm(attr.attr_name, attr)"-->
                                    <!--                              @blur="handleInputConfirm(attr.attr_name, attr)"-->
                                  </el-input>
                                </el-form-item>
                              </div>
                              <div style="margin-left:10px ">
                                <el-form-item label="排序">
                                  <el-input-number v-model="ruleForm.sequence" :min="0"  controls-position="right" size="mini"  placeholder=""></el-input-number>
                                </el-form-item>
                              </div>
                            </div>
                            <div>
                              <el-form-item label="禁用">
                                <div style="display: flex;justify-content: space-between;align-items:center;height: 35px">
                                  <el-switch
                                      style="display: block"
                                      v-model="ruleForm.is_display"
                                      active-text="是"
                                      inactive-text="否"
                                      :active-value="1"
                                      :inactive-value="0">
                                  </el-switch>
                                </div>
                              </el-form-item>
                            </div>
                            <div>
                              <el-form-item  label="图片" prop="">
                                <el-upload
                                    style=" width: 100px;height: 100px"
                                    class="avatar-uploader"
                                    :action="UpURL"
                                    :show-file-list="false"
                                    :http-request="httpRequest"
                                    :before-upload="beforeAvatarUpload">
                                  <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar" />
                                  <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 1080 X 高 972</p>
                              </el-form-item>
                            </div>
                            <div>
                              <div style="color: #606266;margin-bottom: 10px">描述</div>
                              <ueItem ref="childRef" :content="ruleForm.content" :name="num" @getContent="getContent"></ueItem>
                            </div>
                          </el-form>
                        </div>
                       </el-scrollbar>
                        <div style="text-align:right;margin-top: 10px">
                          <el-button type="primary" v-if="nowIndex" class="button-new-tag" size="mini" @click="handleInputConfirm(attr.attr_name,attr,attr.attr_group_name,1)">修改</el-button>
                          <el-button type="primary" v-else class="button-new-tag" size="mini" @click="handleInputConfirm(attr.attr_name,attr,attr.attr_group_name,2)">保存</el-button>
                        </div>
                      </el-drawer>
                    </el-form-item>
                  </el-form>
                </div>
                <div class="goods-content-right">
                  <el-link type="danger" @click="delPrivateSpec(attr,index)">删除规格</el-link>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div style="text-align: right">
          <el-button :loading="loading" size="mini" @click="xiayibu()">下一步</el-button>
          <el-button :loading="loading" type="primary" size="mini" @click="showLower()">保存</el-button>
        </div>
      </div>
      <div v-show="showNum==2||showNum==3">
        <el-table height="calc(100vh - 240px)" border ref="multipleTable" :data="tableColumnList.tableBodyList" stripe tooltip-effect="dark" style="width: 100%;margin-top:1%;">
          <el-table-column v-for="item in tableColumnList.tableHeaderList"  :label="item.group_name" :property="item.prop" :key="item.prop" align="center">
            <template #default="scope">
              <span>{{ scope.row[scope.column.property] }}</span>
            </template>
          </el-table-column>
          <el-table-column key="名称" label="名称">
            <template #default="scope">
              <el-input :disabled="showNum==3" v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="价格">
            <template #default="scope">
              <el-input style="width: 100%" :disabled="showNum==3" :min="0"  v-model.number="scope.row.sale_price"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="起拍数量">
            <template #default="scope">
              <el-input type="number" min="0" :disabled="showNum==3" v-model.number="scope.row.is_exist_count"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="单位">
            <template #default="scope">
              <el-input :disabled="showNum==3" v-model="scope.row.unit"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="143" label="禁用">
            <template #default="scope">
              <div style="display: flex;justify-content: space-between;align-items:center;height: 25px">
                <el-switch
                    :disabled="showNum==3"
                    style="display: block"
                    v-model="scope.row.is_disabled"
                    active-text="禁用"
                    inactive-text="开启"
                    :active-value="1"
                    :inactive-value="0">
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <!--            <el-table-column label="库存预警">-->
          <!--              <template #default="scope">-->
          <!--                <el-input v-model.number="scope.row.low_stock"></el-input>-->
          <!--              </template>-->
          <!--            </el-table-column>-->
        </el-table>
        <div style="text-align: right;margin-top: 10px">
          <el-button :loading="loading" size="mini" @click="showUpper()">上一步</el-button>
          <!--          <el-button type="primary" size="mini" @click="showLower()">下一步</el-button>-->
          <el-button v-show="showNum==2" :loading="loading" type="primary" size="mini" @click="showAdd()">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import {getArea, getbrandselect, getIMG, getUQIMGurl} from "@/api/common";
import * as axios from "../../api/skuApi"//本页面接口
import { ref } from 'vue';
import ueItem from '../../views/ue/ueItem'
export default {
  name: 'Sku',
  props:['nowData'],
  components:{
    ueItem,
  },
  setup() {

    /* ------------------------------定义data ------------------------------*/
    const childRef = ref(null);
    /* ------------------------------处理生命周期 ------------------------------*/
    /* ------------------------------定义methods ---------------------------------*/
    const methods = {

      // 获取调用子组件方法
      getChildFun:()=>{
        childRef.value.dele();
      }
    };

    /* ------------------------------返回数据------------------------------ */
    return Object.assign( methods,{childRef});  //注意定义的方法和变量要return
  },

  data(){
    return{
      nowIndex:'',
      UpURL:'',
      loading:false,
      num:'',
      ruleForm:{
        attr_name:'',
        is_display:0,
        content:'',
        image:'',
        sequence:0,
      },
      showNum:2,
      tableColumnList: {
        tableHeaderList: [],//表头
        tableBodyList: []//表格数据
        // inventory: ''
      },
      attr_group_list: [
        {
          attr_group_name: '', //规格名
          sequence:0,
          attr_list: [], //规格值数组
          inputVisible: false,
          attr_name: ''
        }
      ],
    }
  },

  created() {
    this.onTable('val')
  },
  methods:{
    //LOGO上传图片方法
    httpRequest(options) {
      this.login=true
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.image=url
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })
          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    //
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    /*图片上传*/
    getContent(html){
      this.ruleForm.content=html
      console.log('父组件接受',html)
    },
    onTable(val){
      let data={
        product_id:this.nowData.product_id,
      }
      axios.getSkuData(data,'/api/admin/product-sku/getAttrList').then((res)=>{
        console.log('列表',res)
        if(res.code==200){
          if(res.data.list.length>0){
            if(val){
              this.showNum=3
            }
            this.tableColumnList.tableHeaderList= res.data.tableHead//表头
            this.tableColumnList.tableBodyList= res.data.list//表格数据
          }else {
            this.showNum=1
            this.onserchTag()
          }
        }else {
          this.$message.error(res.msg)
        }

      }).catch((err)=>{

      })
    },//表格数据
    onserchTag(){
      let data={
        product_id:this.nowData.product_id,
      }
      this.loading=true
      axios.getSkuData(data,'/api/admin/product-sku/getSkuTypeList').then((res)=>{
        if(res.code==200){
          this.loading=false
          if(res.data.attr_group_list.length>0){
            this.attr_group_list=res.data.attr_group_list
          }
        }else {
          this.loading=false
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.loading=false
      })
    },//规格数据
    xiayibu(){
      this.showNum+=1
    },
    showLower(){
      if(this.showNum==1){
        let data={
          product_id:this.nowData.product_id,
          attr_group_list:this.attr_group_list
        }
        this.loading=true
        axios.postSkuData(data,'/api/admin/product-sku/changeSkuType').then((res)=>{
          if(res.code==200){
            this.showNum+=1
            this.onTable()
            this.loading=false
          }else {
            this.$message.error(res.msg)
            this.loading=false
          }

        }).catch((err)=>{
          this.loading=false
        })
      }else {

      }
    }, //下一步


    showUpper(){
      if(this.showNum==2){
        this.onserchTag()
      }
      this.showNum-=1
    },//上一步

    showAdd(){
      let data={
        sku_list:this.tableColumnList.tableBodyList
      }
      this.loading=true
      axios.postSkuData(data,'/api/admin/product-sku/changeSku').then((res)=>{
        console.log('列表',res)
        if(res.code==200){
          this.$message.success(res.msg)
          this.showNum+=1
          this.loading=false
        }else {
          this.$message.error(res.msg)
          this.loading=false
        }

      }).catch((err)=>{
        this.loading=false
      })
      console.log('表格数据',this.tableColumnList.tableBodyList)
    },//保存

    // 添加规格
    addPrivateSpec(index) {
      let that=this
      this.loading=true
      this.num=Math.round(Math.random()*(10000)+1000)+Math.round(Math.random()*(10000)+1000)
      this.attr_group_list.push({
        attr_group_name: '',
        sequence:0,
        attr_list: [],
        inputVisible: false,
        attr_name: ''
      })
      setTimeout(()=>{
        that.loading=false
      },2000)
    },
    delPrivateSpec(tag,index) {
      if(tag.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.postSkuData({id:tag.id},'/api/admin/product-sku/delSkuType').then((res)=>{
            if(res.code==200){
              this.$message.success(res.msg)
              this.attr_group_list.splice(index, 1)
            }else {
              this.$message.error(res.msg)
            }
          }).catch((err)=>{
            this.$message.error('服务器请求失败，联系管理员！')
          })
        }).catch(() => {
          this.$message({
            type: 'error',
            message: '已取消删除',
          })
        })
      }else {
        this.attr_group_list.splice(index, 1)
      }
    },
    handleInputConfirm(val, attr,name,inx) {
      console.log('保存')
      if (this.ruleForm.attr_name) {
        if(inx==1){
          this.$message.success('保存成功')
          // this.nowIndex=''
          // attr.attr_list[this.ruleForm.index]=this.ruleForm
        }else if(inx==2){
          attr.attr_list.push({attr_name:this.ruleForm.attr_name,content:this.ruleForm.content,sequence:this.ruleForm.sequence,image:this.ruleForm.image})
        }
        attr.inputVisible = false
      }else {
        this.$message.error('请填写名称')
      }
    },
    offFrawer(){
      console.log('关闭')
      let that=this
      setTimeout(function () {

      },200)
    },
    handleClose(tag, item) {
      if(tag.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.postSkuData({id:tag.id},'/api/admin/product-sku/delSkuItem').then((res)=>{
            if(res.code==200){
              this.$message.success(res.msg)
              item.attr_list.splice(item.attr_list.indexOf(tag.attr_name), 1)
            }else {
              this.$message.error(res.msg)
            }
          }).catch((err)=>{
            this.$message.error('服务器请求失败，联系管理员！')
          })
        }).catch(() => {
          this.$message({
            type: 'error',
            message: '已取消删除',
          })
        })
      }else {
        item.attr_list.splice(item.attr_list.indexOf(tag.attr_name), 1)
      }

    },
    upTag(attr,tag, index){
      this.num=Math.round(Math.random()*(10000)+1000)+Math.round(Math.random()*(10000)+1000)
      this.ruleForm=tag
      this.nowIndex='修改'
      attr.inputVisible = true
    },
    showInput(attr, index) {
      this.num=Math.round(Math.random()*(10000)+1000)+Math.round(Math.random()*(10000)+1000)
      this.nowIndex=''
      this.ruleForm={
        attr_name:'',
        is_display:0,
        content:'',
        image:'',
        sequence:0,
      }
      console.log('编辑器内容',this.ruleForm.content)
      console.log(this.num)
      attr.inputVisible = true
      // const arr = ref([`saveTagInput${index}`][0]);
      //
      // // 存储dom数组
      // const myRef = ref([]);
      //
      // const setRef = (el) => {
      //   myRef.value.push(el);
      // };
      //
      // nextTick(() => {
      //   console.log(arr)
      //   arr.setRef.input.focus()
      //   // console.dir(myRef.value);
      // });
      // this.$nextTick((_) => {
      //   arr.setRef.input.focus()
      // })
    },
    // 笛卡尔积算法
    // cartesianProductOf(...args) {
    //   return args.reduce(
    //       (total, current) => {
    //         let ret = []
    //         total.forEach((a) => {
    //           current.forEach((b) => {
    //             ret.push(a.concat([b]))
    //           })
    //         })
    //         return ret
    //       },
    //       [[]]
    //   )
    // },
  }
}
</script>
<style lang="less" scoped>
.goods-spec {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.goods-spec .goods-spec-add {
  margin-right: 15px;
}
.el-form-item{
  margin-bottom: 0;
}
.goods-container .button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.goods-container .input-new-tag {
  width: 90px;
  margin-right: 10px;
  vertical-align: bottom;
}
.goods-container .el-tag {
  margin-right: 10px;
}
.goods-container .goods-content {
  margin-bottom: 10px;
  padding: 14px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  background-color: #fcfcfc;
}
.goods-content .goods-content-box {
  display: flex;
  align-items: center;
}
.goods-content-box .goods-content-left {
  flex: 1;
}
</style>